.Dashboard {
  .greeting {
  }
  .WidgetContainer {
    display: flex;
    // flex-direction: row;
    margin-top: 24px;
    .WidgetColumn {
      display: flex;
      //   padding-right: 30px;
      flex-wrap: wrap;
      //   flex: 1;
      .widgetCard {
        background-color: white;
        border-radius: 16px;
        min-width: 142px;
        min-height: 192px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin: 0 24px 24px 0px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 102px;
          opacity: 0.7;
        }
        &:hover {
          transition: all 0.2s ease-out, transform 0.2s ease-out;

          transform: scale(1.03);
        }
      }
    }
    .WidgetColumn:last-child {
      padding-right: 0;
      //   padding-left: 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  //tablet
  .greeting {
    margin-top: 24px !important;
  }
  .WidgetContainer {
    flex-direction: column;
    .WidgetColumn {
      width: 100% !important;
      .widgetCard:first-child {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .WidgetContainer {
    flex-direction: column;
    .WidgetColumn {
      width: 100% !important;
      .widgetCard {
        width: 100% !important;
        margin-right: 0 !important;
      }
    }
  }
}
