.CreateClass {
  margin-top: 24px;
  overflow: hidden;
  .InviteSelectors {
    // padding-bottom: 24px;
    .SelectorContainer {
      width: 100%;
      margin-bottom: 18px;
    }
  }
  .formContainer {
    .form-row {
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 8px;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      margin-bottom: 24px;
      padding: 2px;
      width: 100%;
      .input-element {
        width: 100%;
        input {
          height: 2.2rem;
        }
      }
      span {
        cursor: pointer;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
  }
}
