@import "../../../../theme/utilities/variable.scss";

.onboarding {
  .container {
    display: flex;
    height: 100vh;
    width: 100%;
    background: $background-color;

    .right-container {
      width: 60%;
      height: 100%;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .left-container {
      padding: 4rem 2.6rem;
      box-sizing: border-box;
      align-items: flex-start;
      display: flex;
      width: 40%;
      flex-direction: column;
      padding-left: 8%;
      padding-right: 8%;

      .forms {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .appLogo {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 74px;
          img {
            width: 92px;
          }
        }
        .input-element {
          margin: 1.4rem 0;

          input {
            height: 2.8rem;
          }
        }

        .action-buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 4rem;
        }
      }
    }
  }

  border-color: #57a8e9 !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

//ant modifierds
.ant-select-selector {
  background-color: #7c8091 !important;
  color: white !important;
  span {
    font-size: 14px !important;
  }
}
