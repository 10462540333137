


p {
  display: block;
  margin-block-start: 2px;
  margin-block-end: 2px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h5{
  margin-block-start: 4px;
  margin-block-end: 4px;
}


h6{
  margin-block-start: 4px;
  margin-block-end: 4px;
}

@media only screen and (max-width: 768px) {
  .ant-layout{
    /* height: auto !important; */
    background-color: rgb(240, 242, 253);
    margin-left: 0;
  
  }

}

@media only screen and (max-width: 600px) {

.ant-layout{
  height: auto !important;
  margin-left: 0;

}
.MasterLayoutContent{
  margin-left: 0 !important;

}

}

.MasterLayoutContent{
  margin-left: 256px;

}

