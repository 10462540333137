.masterLayout {
  background-color: rgb(240, 242, 253);
  .leftSideNav {
    padding-top: 0;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .LogoMenuContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      .appLogoContainer {
        padding: 20px;
        display: flex;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 40px;
        img {
          width: 164px;
        }
      }
      .userInfoContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;
        margin-top: 8px;

        .profileImage {
          width: 84px;
          height: 84px;
          object-fit: cover;
          border-radius: 50%;
          margin-bottom: 12px;
        }
        .userInfo {
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .menuContainer {
        flex: 1;
        display: flex;
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;
      }
    }

    .logout {
      display: flex;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
      cursor: pointer;
    }
  }

  //ant design modifiers
  .ant-menu-root {
    border-inline-end: 0 !important;
    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 100% !important;
      margin: 0 !important;
    }
  }
  .ant-menu-item-selected {
    background: linear-gradient(
      90deg,
      rgba(95, 136, 233, 0.126994) 0%,
      rgba(49, 80, 206, 0.00204873) 100%
    ) !important;
    border-left: 3px solid #ff0000;
  }

  // @media screen and (min-width: 1280px) {
  //   //For Big TV's (HD Screens)
  //   .ant-layout-content {
  //     padding-left: 15%;
  //   }
  // }
}

.ant-select-selector {
  background-color: white !important;
  color: var(--absolute-black) !important;
  span {
    font-size: 14px !important;
  }
}

.ant-input[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border: 1px solid #d9d9d9 !important;
}
.ant-select-selector[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border: 1px solid #d9d9d9 !important;
}
